
	import { Component, Prop, Vue } from 'vue-property-decorator'
	import LsDialog from '@/components/ls-dialog.vue'
	import LsPagination from '@/components/ls-pagination.vue'
	import config from '@/config'
	import {
		orderInfoApi
	} from '@/api/visa/order';
	// import { apiOrderConfirmPay } from '@/api/order'
	// import UploadVoucher from './upload-voucher.vue'
	@Component({
		components: {
			LsDialog,
			LsPagination,
			// UploadVoucher
		}
	})

	export default class OrderPane extends Vue {
		@Prop() value : any
		@Prop() pager! : any
		group = {}
		dialogVisible = false
		showPay = false
		dialogFormVisible = false
		orderId = 0
		selectIds : any = []
		remarks = '' //商家备注

		fileUrl = `${config.baseURL}/platformapi/upload/upload_new_license`
		version = config.version
		header = {
			token: this.$store.getters.token,
			version: this.version
		}
		// 获取订单信息
		getOrderLists() {
			(this.$parent as any).getOrderLists();
		}
		confirmPay(id : number) {
			this.orderId = id
			this.showPay = true
		}
		openGroup(row : any) {
			if (!row || row.group_id <= 0) {
				return this.$message.error('团号不存在！')
			}
			orderInfoApi({
				id: row.id
			}).then((res : any) => {
				this.group = res;
				this.dialogVisible = true;
			}).catch((err : any) => {
				return this.$message.error(err.msg)
			});

		}
		handleClose() {
			this.dialogVisible = false;
		}
		// 删除
		delOrder(id : number) {
			this.orderId = id
			this.dialogFormVisible = true;
		}
		copy(id : number) {
			(this.$parent as any).copyOrder(id);
		}
		// 删除
		delOrderTrue() {
			this.dialogFormVisible = false;
			(this.$parent as any).delOrder(this.orderId);
		}
		// 去订单详情
		toOrder(id : any) {
			this.$router.push({
				path: '/visa/edit',
				query: { id }
			})
		}

		handleBeforeUpload() {
			this.pager.loading = true;
		}
		handleSuccess(res : any, file : any) {
			this.pager.loading = false;
			if (file.response.code) {
				this.$message.success('替换成功')
				return (this.$parent as any).getOrderLists();
			} else {
				return this.$message.error('上传失败')
			}
		}
	}
